// src/components/LeaveConfirmationPopup.js
import React from 'react';
import { X } from 'lucide-react';
import '../assets/styles/LeaveConfirmationPopup.css';

const LeaveConfirmationPopup = ({ onSave, onLeave, onClose }) => {
  return (
    <div className="leave-confirmation-overlay">
      <div className="leave-confirmation-content">
        <div className="leave-confirmation-header">
          <h3>Unsaved Changes</h3>
          <button 
            className="close-button"
            onClick={onClose}
          >
            <X size={20} />
          </button>
        </div>
        <div className="leave-confirmation-body">
          <p>Are you sure you want to leave without saving?</p>
        </div>
        <div className="leave-confirmation-footer">
          <button 
            className="secondary-button"
            onClick={onLeave}
          >
            Yes, Leave
          </button>
          <button 
            className="primary-button"
            onClick={onSave}
          >
            Save and Exit
          </button>
        </div>
      </div>
    </div>
  );
};

export default LeaveConfirmationPopup;