import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
  useCallback,
} from "react";
import { useNavigate, Link } from "react-router-dom";
import AuthLogin from "../profile/auth-login";
import Input from "./Input";
import "../assets/styles/LoginInput.css";

const FINNTECH_API_URL = process.env.REACT_APP_API_URL;

// Reducer to manage username input and validation state
const usernameReducer = (state, action) => {
  if (action.type === "USER_INPUT") {
    return { value: action.val, isValid: action.val.trim().length > 6 };
  }
  if (action.type === "INPUT_BLUR") {
    return { value: state.value, isValid: state.value.trim().length > 6 };
  }
  return { value: "", isValid: false };
};

// Reducer to manage password input and validation state
const passwordReducer = (state, action) => {
  if (action.type === "USER_INPUT") {
    return { value: action.val, isValid: action.val.trim().length > 6 };
  }
  if (action.type === "INPUT_BLUR") {
    return { value: state.value, isValid: state.value.trim().length > 6 };
  }
  return { value: "", isValid: false };
};

const LoginInput = (props) => {
  const [loginIsValid, setLoginIsValid] = useState(false);
  const [content, setContent] = useState(null);
  const [isLocked, setIsLocked] = useState(false);
  const navigate = useNavigate();

  const [usernameState, dispatchUsername] = useReducer(usernameReducer, {
    value: "",
    isValid: null,
  });

  const [passwordState, dispatchPassword] = useReducer(passwordReducer, {
    value: "",
    isValid: null,
  });

  const ctx = useContext(AuthLogin);
  const usernameInputRef = useRef();
  const passwordInputRef = useRef();

  useEffect(() => {
    const identifier = setTimeout(() => {
      setLoginIsValid(usernameState.isValid && passwordState.isValid);
    }, 250);

    return () => {
      clearTimeout(identifier);
    };
  }, [usernameState.isValid, passwordState.isValid]);

  const usernameChangeHandler = (event) => {
    dispatchUsername({ type: "USER_INPUT", val: event.target.value });
  };

  const passwordChangeHandler = (event) => {
    dispatchPassword({ type: "USER_INPUT", val: event.target.value });
  };

  const validateUsernameHandler = () => {
    dispatchUsername({ type: "INPUT_BLUR" });
  };

  const validatePasswordHandler = () => {
    dispatchPassword({ type: "INPUT_BLUR" });
  };

  const submitHandler = async (event) => {
    event.preventDefault();

    if (loginIsValid) {
      try {
        const userData = await validateLogin(usernameState.value, passwordState.value);
        
        if (userData[0]) {
          const firstTimeLogin = await isFirstTimeLogin(userData[0].user_id);
          const userAcceptedTAndC = await userAcceptedTermsAndConditions(userData[0].user_id);

          ctx.onLogin(
            userData[0].username, 
            userData[0].firstname + " " + userData[0].lastname,
            userData[0].role, 
            userData[0].user_id, 
            firstTimeLogin, 
            userAcceptedTAndC, 
            userData[0].password,
            userData[0].issubscribed
          );

          navigate("/my-dashboard");
        }
      } catch (error) {
        setContent(
          <div className={`errormessage ${isLocked ? 'locked' : ''}`}>
            {error.message}
            {isLocked && (
              <div className="lockout-message">
                <Link to="/forgot-password" className="reset-link">
                  Reset your password
                </Link>
              </div>
            )}
          </div>
        );
      }
    } else {
      setContent(
        <div className="errormessage">
          Please enter a valid username and password
        </div>
      );
    }
  };

  const validateLogin = useCallback(async (username, password) => {
    const url = `${FINNTECH_API_URL}/username/${username}/password/${password}`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
        }
      });

      const data = await response.json();

      if (!response.ok) {
        if (response.status === 429) {
          // Too many attempts - account is locked
          setIsLocked(true);
          throw new Error(`Account temporarily locked. ${data.message}`);
        } else if (response.status === 401) {
          // Invalid credentials with remaining attempts
          throw new Error(`Invalid username or password. ${data.message}`);
        }
        throw new Error('Login failed. Please try again.');
      }

      return data;
    } catch (error) {
      throw error;
    }
  }, []);

  const isFirstTimeLogin = useCallback(async (user_id) => {
    const url = `${FINNTECH_API_URL}/isUserFirstTimeLogin/${user_id}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      }
    });

    if (!response.ok) {
      throw new Error();
    }

    const data = await response.text();
    console.log(data);
    return data;
  }, []);

  const userAcceptedTermsAndConditions = useCallback(async (user_id) => {
    const url = `${FINNTECH_API_URL}/userAcceptedTermsAndConditions/${user_id}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      }
    });

    if (!response.ok) {
      throw new Error();
    }

    const data = await response.text();
    return data;
  }, []);

  return (
    <>
      {content}
      <div className="dialogue">
        <form onSubmit={submitHandler}>
          <h1>LOGIN</h1>
          <p>Enter Email and Password to Sign In</p>
          <Input
            ref={usernameInputRef}
            value={usernameState.value}
            id="username"
            onChange={usernameChangeHandler}
            onBlur={validateUsernameHandler}
            disabled={isLocked}
          />
          <br />
          <Input
            ref={passwordInputRef}
            value={passwordState.value}
            id="password"
            onChange={passwordChangeHandler}
            onBlur={validatePasswordHandler}
            type="password"
            disabled={isLocked}
          />
          <br />
          <button className="submitbtn" disabled={isLocked}>
            Log In
          </button>
          <Link to="/signup" className="createaccountbtn">
            Create Account
          </Link>
          <br />
          <Link to="/forgot-password" className="forgot">
            Forgot Password?
          </Link>
          <br />
        </form>
      </div>
    </>
  );
};

export default LoginInput;