/**
 * Graph.js - A reusable chart component for the dashboard
 * 
 * This component renders individual charts within the dashboard grid.
 * It provides features like tooltips, expandable view functionality, and
 * conditional add/remove buttons based on the current mode.
 */

// React core imports
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom'; // For creating portals to render outside component hierarchy
import '../assets/styles/Graph.css';
import { Card } from './Card'; // Card wrapper component
// Icon imports from lucide-react library for UI controls
import { HelpCircle, Maximize2, X, Plus, Trash } from 'lucide-react';
import ReactTooltip from 'react-tooltip'; // For displaying tooltips on hover



const Graph = (props) => {
  // State to track if chart is in expanded (fullscreen) mode
  const [isExpanded, setIsExpanded] = useState(false);

  // Handler to toggle between normal and expanded view
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  // Plus button component - rendered conditionally when showPlus is true (in Add Charts view)
  const plus = (
    <button className="control-button"
    onClick={(e) => {
      e.stopPropagation(); // Prevent event bubbling to parent elements
      props.onAdd(); // Call the parent component's add handler
    }}>
      <Plus className="control-icon" size={20} />
    </button>
  );

  // Trash button component - rendered conditionally when in edit mode
  const trash = (
    <button 
      className="control-button"
      onClick={(e) => {
        e.stopPropagation(); // Prevent event bubbling to parent elements
        props.onRemove(); // Call the parent component's remove handler
      }}
    >
      <Trash className="control-icon" size={20} />
    </button>
  );

  // Effect to ensure tooltips are properly initialized and updated
  useEffect(() => {
    // When component mounts or tooltip content changes
    if (typeof ReactTooltip.rebuild === 'function') {
      // Small timeout to ensure DOM is ready before rebuilding tooltips
      const timer = setTimeout(() => {
        ReactTooltip.rebuild();
      }, 100);
      
      // Cleanup function to prevent memory leaks
      return () => clearTimeout(timer);
    }
  }, [props.tooltip]); // Re-run when tooltip content changes

  return (
    <>
      {/* Normal view - chart in a card container */}
      <Card key={props.id} className="chart-card">
        <div className="chart-header">
          {/* Help icon with tooltip functionality */}
          <HelpCircle 
            data-tip={props.tooltip} // Tooltip content
            data-for="dashboard-tooltip" // Links to tooltip container in TooltipWrapper
            className="help-icon"
          />
          {/* Chart title */}
          <h3 className="chart-title">{props.title}</h3>
          
          {/* Chart control buttons container */}
          <div className="chart-controls">
            {/* 
              Conditional rendering:
              - In "Add Charts" view (showPlus=true): Show plus button
              - In Dashboard edit mode (isEditMode=true): Show trash button
              - Otherwise: Show neither
            */}
            {props.showPlus ? plus : (props.isEditMode && trash)}
            
            {/* Expand button - always visible */}
            <button 
              className="control-button"
              onClick={toggleExpand}
            >
              <Maximize2 className="control-icon" size={20} />
            </button>
          </div>
        </div>
        
        {/* 
          Chart content - loaded via iframe 
          Using iframe allows external chart content to be embedded safely
        */}
        <iframe 
          src={props.url} 
          className='chart-placeholder'
        />
      </Card>

      {/* 
        Expanded view - creates a portal to render outside normal DOM hierarchy
        Only rendered when isExpanded state is true
      */}
      {isExpanded && 
        ReactDOM.createPortal(
          <div className="expanded-overlay">
            <div className="expanded-content">
              <div className="expanded-header">
                <h3 className="chart-title">{props.title}</h3>
                {/* Close button for expanded view */}
                <button 
                  className="dashboard-close-button"
                  onClick={toggleExpand}
                >
                  <X className="control-icon" size={24} color='black' />
                </button>
              </div>
              {/* Chart content in expanded view - same iframe source */}
              <iframe 
                src={props.url} 
                className='expanded-chart'
              />
            </div>
          </div>,
          document.body  // Portal target - renders directly into document body for proper layering
        )}
    </>
  );
};

export default Graph;