import React, { useCallback, useContext, useEffect, useState } from 'react';
import GridLayout from 'react-grid-layout';
import { Pencil, Save } from 'lucide-react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../assets/styles/Dashboard.css';
import 'react-grid-layout/css/styles.css';
import AccountDropdown from '../components/AccountDropdown';
import ReportBugPopup from '../components/ReportBugPopup';
import Graph from '../components/Graph';
import AuthLogin from '../profile/auth-login';
import TermsAndConditionsPopup from '../components/TermsAndConditionsPopup';
import TooltipWrapper from '../components/ToolTipWrapper';
import LeaveConfirmationPopup from '../components/LeaveConfirmationPopup';
import logo from '../assets/images/logo.png';
import reportBug from '../assets/images/Bug.png';
import user from '../assets/images/User.png';

/**
 * Dashboard Component
 * 
 * Main dashboard view that displays user's customizable chart grid.
 * Features include:
 * - Dynamic grid layout with drag-and-drop capability
 * - Edit mode for repositioning and resizing charts
 * - Layout persistence to backend
 * - Unsaved changes detection
 * - Navigation handling with confirmation for unsaved changes
 */
const Dashboard = () => {
  // Authentication context for user information and state
  const ctx = useContext(AuthLogin);
  const navigate = useNavigate();
  const location = useLocation();
  
  // Core state management
  const [charts, setCharts] = useState([]); // Stores the chart data fetched from API
  const [isEditMode, setIsEditMode] = useState(false); // Tracks if user is in layout editing mode
  const [layout, setLayout] = useState([]); // Stores the grid layout configuration
  const [windowWidth, setWindowWidth] = useState(window.innerWidth - 48); // Tracks viewport width for responsive grid
  
  // Unsaved changes management
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false); // Tracks if layout changes are unsaved
  const [showLeaveConfirmation, setShowLeaveConfirmation] = useState(false); // Controls leave confirmation popup
  const [pendingNavigation, setPendingNavigation] = useState(null); // Stores navigation path when confirmation is needed

  /**
   * Window resize handler
   * Updates grid width when window is resized to maintain responsive layout
   */
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth - 48); // Subtracting padding
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  /**
   * Unsaved changes detection
   * Warns user when attempting to leave page with unsaved changes
   */
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isEditMode && hasUnsavedChanges) {
        // Standard way to show browser's confirm dialog
        e.preventDefault();
        e.returnValue = ''; // Chrome requires returnValue to be set
        return ''; // This message isn't shown in modern browsers
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [isEditMode, hasUnsavedChanges]);

  // API endpoint from environment variables
  const FINNTECH_API_URL = process.env.REACT_APP_API_URL;

  /**
   * Generates default layout grid positions for charts
   * Used when initializing dashboard or adding new charts
   * 
   * @param {Array} charts - Array of chart objects
   * @returns {Array} Layout configuration array for react-grid-layout
   */
  const generateDefaultLayout = (charts) => {
    return charts.map((chart, i) => ({
      i: chart.id.toString(),
      x: (i % 3) * 8,  // Position in columns (3 charts per row)
      y: Math.floor(i / 3) * 2, // Position in rows
      w: 6,            // Width in grid units
      h: 2,            // Height in grid units
      minW: 4,         // Minimum allowed width
      maxW: 12         // Maximum allowed width
    }));
  };

  /**
   * Fetch user's dashboard configuration
   * Retrieves chart data and layout from API on component mount
   */
  useEffect(() => {
    const fetchCharts = async () => {
      try {
        const url = `${FINNTECH_API_URL}/getUserDashboard/${ctx.userId}`;
        const response = await fetch(url);
        const data = await response.json();

        if (data.charts && data.layouts) {
          setCharts(data.charts);
          setLayout(data.layouts);
        } else {
          // Handle case where user has no dashboard yet
          setCharts([]);
          setLayout([]);
        }
      } catch (error) {
        console.error('Error fetching dashboard:', error);
        setCharts([]);
        setLayout([]);
      }
    };
    fetchCharts();
  }, [ctx.userId]);

  /**
   * Removes a chart from the dashboard
   * Updates both local state and persists removal to backend
   * 
   * @param {number} chartId - ID of the chart to remove
   */
  const handleRemoveFromDashboard = async (chartId) => {
    try {
      // Update local state immediately for responsive UI
      setCharts(prevCharts => prevCharts.filter(chart => chart.id !== chartId));
      setLayout(prevLayout => prevLayout.filter(item => item.i !== chartId.toString()));
      setHasUnsavedChanges(true);
      
      const requestBody = {
        "chartIdToRemove": chartId
      };
      
      if (!chartId) {
        throw new Error('chartId is required');
      }
      
      // Persist changes to backend
      const url = `${process.env.REACT_APP_API_URL}/deleteFromDashboard/${ctx.userId}`;
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(requestBody)
      });
      
      const responseText = await response.text();
      
      if (!response.ok) {
        throw new Error(`Failed to update dashboard: ${responseText}`);
      }
    } catch (error) {
      console.error('Error updating dashboard:', error);
      alert(`Failed to remove chart: ${error.message}`);
      
      // Attempt to refresh dashboard data if update fails
      try {
        const fetchResponse = await fetch(`${process.env.REACT_APP_API_URL}/getUserDashboard/${ctx.userId}`);
        const data = await fetchResponse.json();
        setCharts(data);
        setLayout(generateDefaultLayout(data));
      } catch (refreshError) {
        console.error('Error refreshing dashboard:', refreshError);
      }
    }
  };

  /**
   * Saves the current layout configuration to the backend
   * Called when user clicks Save Layout button
   * Also handles pending navigation if present
   */
  const handleSaveLayout = async () => {
    try {
      // console.log('Current layout state:', layout); // Debug log
  
      const layoutData = {
        layouts: layout
      };
      
      // console.log('Sending layout data:', layoutData); // Debug log
      
      const url = `${FINNTECH_API_URL}/saveLayout/${ctx.userId}`;
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(layoutData)
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to save layout');
      }
  
      const result = await response.json();
      // console.log('Save layout response:', result); // Debug log
  
      // Reset edit state
      setIsEditMode(false);
      setHasUnsavedChanges(false);
      
      // Handle pending navigation if exists
      if (pendingNavigation) {
        navigate(pendingNavigation);
        setPendingNavigation(null);
      }
      
      // Close the confirmation popup if it was open
      setShowLeaveConfirmation(false);
    } catch (error) {
      console.error('Error saving layout:', error);
      alert('Failed to save layout configuration');
    }
  };

  /**
   * Logs user acceptance of Terms and Conditions to backend
   * 
   * @param {string} user_id - User ID to log acceptance for
   * @returns {Promise} API response JSON
   */
  const logUserAcceptedTermsAndConditions = useCallback(async (user_id) => {
    const url = `${FINNTECH_API_URL}/logUserSignedTermsAndConditions/${user_id}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      }
    });

    if (!response.ok) {
      throw new Error();
    }

    return await response.json();
  }, []);

  /**
   * Handles user acceptance of Terms and Conditions
   * Updates local storage and context state
   */
  const handleTermsAndConditionsAccept = async (event) => {
    const log = await logUserAcceptedTermsAndConditions(ctx.userId);
    const userData = JSON.parse(localStorage.getItem('userInfo') || '[]');
    
    // Update user data in local storage
    const updatedUserData = {
      ...userData,
      firstTimeLogin: "false",
      userAcceptedTermsAndConditions: "true"
    };
    
    localStorage.setItem('userInfo', JSON.stringify(updatedUserData));
    ctx.setFirstTimeLogin(false);
    ctx.setuserAcceptedTermsAndConditions(true);
  };

  /**
   * Handles grid layout changes
   * Updates layout state and tracks unsaved changes
   * 
   * @param {Array} newLayout - New layout configuration from react-grid-layout
   */
  const onLayoutChange = (newLayout) => {
    setLayout(newLayout);
    if (isEditMode) {
      setHasUnsavedChanges(true);
    }
  };
  
  /**
   * Smart navigation handler
   * Checks for unsaved changes before navigation and shows confirmation if needed
   * 
   * @param {string} path - Target navigation path
   */
  const handleNavigation = (path) => {
    if (isEditMode && hasUnsavedChanges) {
      // Store pending navigation and show confirmation
      setPendingNavigation(path);
      setShowLeaveConfirmation(true);
    } else {
      // Direct navigation if no unsaved changes
      navigate(path);
    }
  };
  
  /**
   * Handles navigation without saving changes
   * Called when user confirms they want to leave without saving
   */
  const handleLeaveWithoutSaving = () => {
    setIsEditMode(false);
    setHasUnsavedChanges(false);
    setShowLeaveConfirmation(false);
    
    if (pendingNavigation) {
      navigate(pendingNavigation);
      setPendingNavigation(null);
    }
  };
  
  /**
   * Closes the confirmation popup without taking action
   * Cancels pending navigation
   */
  const handleCloseConfirmation = () => {
    setShowLeaveConfirmation(false);
    setPendingNavigation(null);
  };
  
  /**
   * Custom Header Component
   * Modified version of LoggedInHeader that integrates with unsaved changes management
   * Uses handleNavigation instead of direct navigation
   */
  const CustomHeader = () => {
    const location = useLocation();
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    
    // Navigation items with their paths
    const navItems = [
      { name: 'My Dashboard', path: '/my-dashboard' },
      { name: 'Add Charts', path: '/add-charts' },
      { name: 'Live Charts', path: '/live-charts' },
      { name: 'Blog', path: '/blogged-in' },
      { name: 'Learn', path: '/learn' },
      { name: 'Submit Idea', path: '/submit-idea' }
    ];
    
    return (
      <div className="header-container">
        <div className="header-content">
          {/* Logo Section */}
          <div 
            className="logo-section" 
            onClick={() => handleNavigation('/')} 
            style={{ cursor: 'pointer' }}
          >
            <img src={logo} alt="FINNTECH" className="logged-in-logo" />
          </div>
          
          {/* Navigation Links */}
          <nav className="nav-links">
            {navItems.map((item) => (
              <div
                key={item.path}
                className={`nav-item ${location.pathname === item.path ? 'selected' : ''}`}
                onClick={() => handleNavigation(item.path)}
              >
                {item.name}
              </div>
            ))}
          </nav>
          
          {/* Right Icons - Special handling for edit mode */}
          <div className="right-icons">
            <div className="icon-container">
              <img 
                src={reportBug} 
                alt="Report Bug" 
                className="header-icon"
                onClick={() => {
                  if (isEditMode && hasUnsavedChanges) {
                    // Show confirmation before opening popup
                    setPendingNavigation(null); // Special case, we'll handle opening the popup in the callback
                    setShowLeaveConfirmation(true);
                  } else {
                    setIsPopupOpen(true);
                  }
                }}
              />
            </div>
            <div className="icon-container">
              <img 
                src={user} 
                alt="User Profile" 
                className="header-icon"
                onClick={() => {
                  if (isEditMode && hasUnsavedChanges) {
                    // Show confirmation before opening dropdown
                    setPendingNavigation(null); // Special case for dropdown
                    setShowLeaveConfirmation(true);
                  } else {
                    setIsDropdownOpen(!isDropdownOpen);
                  }
                }}
              />
              {/* Only show dropdown if not in edit mode */}
              {isDropdownOpen && !isEditMode && <AccountDropdown handleNavigation={handleNavigation} />}
            </div>
          </div>
        </div>
        
        {/* Bug report popup - only shown when not in edit mode */}
        {isPopupOpen && !isEditMode && (
          <ReportBugPopup onClose={() => setIsPopupOpen(false)} />
        )}
      </div>
    );
  };

  return (
    <>
      {/* Show Terms & Conditions for first-time users */}
      {(!ctx.userAcceptedTermsAndConditions) ? (
        <TermsAndConditionsPopup onAccept={handleTermsAndConditionsAccept} />
      ) : (
        <>
          {/* Main dashboard UI */}
          <CustomHeader />
          <div className="dashboard">
            {/* Edit mode controls */}
            <div className="dashboard-controls">
              {isEditMode ? (
                <button 
                  className="control-button save-button"
                  onClick={handleSaveLayout}
                >
                  <Save className="control-icon" />
                  Save Layout
                </button>
              ) : (
                <button 
                  className="control-button edit-button"
                  onClick={() => setIsEditMode(true)}
                >
                  <Pencil className="control-icon" />
                  Edit Layout
                </button>
              )}
            </div>
            
            {/* Dynamic grid layout container */}
            <div className="dashboard-grid-container">
              <GridLayout
                className="dashboard-grid"
                layout={layout}
                cols={24} // Total number of grid columns
                rowHeight={150} // Height of each grid row in pixels
                width={windowWidth}
                isDraggable={isEditMode} // Only allow dragging in edit mode
                isResizable={isEditMode} // Only allow resizing in edit mode
                onLayoutChange={onLayoutChange}
                margin={[8, 8]} // Space between items horizontally and vertically
                containerPadding={[8, 8]} // Padding inside container
                compactType={null} // Disable automatic compacting
              >
                {/* Map chart data to grid items */}
                {charts.map((chart) => (
                  <div key={chart.id.toString()} className="chart-wrapper">
                    <Graph
                      {...chart}
                      showPlus={false}
                      isEditMode={isEditMode} // Pass edit mode state to control remove button visibility
                      onRemove={() => handleRemoveFromDashboard(chart.id)}
                    />
                  </div>
                ))}
              </GridLayout>
            </div>
          </div>
          
          {/* Tooltip container for chart help icons */}
          <TooltipWrapper/>
          
          {/* Leave confirmation popup - shown when attempting to navigate with unsaved changes */}
          {showLeaveConfirmation && (
            <LeaveConfirmationPopup 
              onSave={handleSaveLayout}
              onLeave={handleLeaveWithoutSaving}
              onClose={handleCloseConfirmation}
            />
          )}
        </>
      )}
    </>
  );
};

export default Dashboard;