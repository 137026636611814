import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import '../assets/styles/ToolTipWrapper.css'

// This component only renders once and stays separate from the grid layout
const TooltipWrapper = () => {
  useEffect(() => {
    // Initial tooltip setup
    ReactTooltip.rebuild();
    
    // Clean up on unmount
    return () => {
      ReactTooltip.hide();
    };
  }, []);

  // Use the afterShow and afterHide props to prevent continuous updates
  return (
    <ReactTooltip 
      id="dashboard-tooltip"
      place="top" 
      effect="solid"
      delayShow={200}
      insecure={false}
      className="tooltip-fixed"
      afterShow={() => {
        // Prevent further position updates after initial show
        return false;
      }}
    />
  );
};

export default TooltipWrapper;