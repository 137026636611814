import React, { useState, useContext, useEffect } from 'react';
import AuthLogin from '../profile/auth-login';
import NavBar from '../components/NavBar';
import '../assets/styles/MyAccount.css';
import LoggedInHeader from '../components/LoggedInHeader';
import PaymentMethods from '../components/PaymentMethods';
import SubscriptionUpgrade from '../components/SubscriptionUpgrade';

// Custom Modal Component
const Modal = ({ isOpen, onClose, title, children, actions }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h3>{title}</h3>
          <button className="modal-close" onClick={onClose}>&times;</button>
        </div>
        <div className="modal-body">
          {children}
        </div>
        {actions && (
          <div className="modal-footer">
            {actions}
          </div>
        )}
      </div>
    </div>
  );
};

const MyAccount = () => {
  const ctx = useContext(AuthLogin);
  const [showPasswordChange, setShowPasswordChange] = useState(false);
  const [showCancelConfirm, setShowCancelConfirm] = useState(false);
  const [showUpgradeConfirm, setShowUpgradeConfirm] = useState(false);
  const [showEditBilling, setShowEditBilling] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loadError, setLoadError] = useState(null);
  const [accountDetails, setAccountDetails] = useState({
    user: null,
    subscription: null,
    billing: null
  });
  
  // Form states
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [formError, setFormError] = useState('');
  const [formSuccess, setFormSuccess] = useState('');

  useEffect(() => {
    loadAccountDetails();
  }, []);

  const loadAccountDetails = async () => {
    setIsLoading(true);
    setLoadError(null);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/account-details`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          userId: ctx.userId,
          password: ctx.encryptedPassword
        })
      });

      if (!response.ok) {
        throw new Error('Failed to load account details');
      }

      const data = await response.json();
      setAccountDetails(data);
    } catch (error) {
      console.error('Error loading account details:', error);
      setLoadError('Failed to load account details. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };


  const validatePassword = (password) => {
    return {
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /\d/.test(password),
      special: /[!@#$%^&*(),.?":{}|<>]/.test(password)
    };
  };

  const handlePasswordChange = async (event) => {
    event.preventDefault();
    setFormError('');
    setFormSuccess('');

    if (!currentPassword) {
      setFormError('Current password is required');
      return;
    }

    if (newPassword !== confirmPassword) {
      setFormError('New passwords do not match');
      return;
    }

    const passwordChecks = validatePassword(newPassword);
    if (!Object.values(passwordChecks).every(Boolean)) {
      setFormError('Password must be at least 8 characters and include uppercase, lowercase, numbers, and special characters');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/change-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user: ctx.user,
          userId: ctx.userId,
          currentPassword: currentPassword,
          newPassword: newPassword
        })
      });

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error || 'Failed to change password');
      }

      setFormSuccess('Password changed successfully');
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
      setShowPasswordChange(false);
    } catch (error) {
      setFormError(error.message);
    }
  };



  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const handleCancelMembership = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/cancel-subscription`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: ctx.userId,
        })
      });

      if (!response.ok) {
        throw new Error('Failed to cancel subscription');
      }

      await loadAccountDetails();
      setFormSuccess('Subscription cancelled successfully');
      setShowCancelConfirm(false);
      setTimeout(() => setFormSuccess(''), 3000);
    } catch (err) {
      setFormError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePlanChange = async (newPriceId) => {
    try {
      setIsLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/update-subscription`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: ctx.userId,
          newPriceId
        })
      });

      if (!response.ok) {
        throw new Error('Failed to update subscription');
      }

      await loadAccountDetails();
      setFormSuccess('Subscription updated successfully');
      setShowUpgradeConfirm(false);
      setTimeout(() => setFormSuccess(''), 3000);
    } catch (err) {
      setFormError(err.message);
    } finally {
      setIsLoading(false);
    }
  };



  if (isLoading) {
    return (
      <>
        <LoggedInHeader />
        <div className="my-account-container">
          <div className="loading-message">Loading account details...</div>
        </div>
      </>
    );
  }

  if (loadError) {
    return (
      <>
        <LoggedInHeader />
        <div className="my-account-container">
          <div className="error-section">
            <div className="error-message">{loadError}</div>
            <button 
              className="action-button"
              onClick={loadAccountDetails}
            >
              Retry
            </button>
          </div>
        </div>
      </>
    );
  }

  const { user, subscription, billing } = accountDetails;

  return (
    <>
      <LoggedInHeader />
      <div className="my-account-container">
        <h1>My Account</h1>
        
        {formError && <div className="error-message">{formError}</div>}
        {formSuccess && <div className="success-message">{formSuccess}</div>}
        
        <div className="account-section">
          <h2>Profile Information</h2>
          <div className="info-grid">
            <div className="info-item">
              <label>Username</label>
              <p>{user?.username || 'N/A'}</p>
            </div>
            <div className="info-item">
              <label>Name</label>
              <p>{user ? `${user.firstname} ${user.lastname}` : 'N/A'}</p>
            </div>
            <div className="info-item">
              <label>Email</label>
              <p>{user?.email || 'N/A'}</p>
            </div>
          </div>
        </div>

        <div className="account-section">
          <h2>Subscription Details</h2>
          {subscription ? (
            <div className="info-grid">
              <div className="info-item">
                <label>Plan Type</label>
                <p>{subscription.subscription_type || 'N/A'}</p>
              </div>
              <div className="info-item">
                <label>Status</label>
                <p>
                  <span className={`status-indicator ${subscription.cancelled ? 'cancelled' : 'active'}`}>
                    {subscription.cancelled ? 'Cancelled' : 'Active'}
                  </span>
                </p>
              </div>
              <div className="info-item">
                <label>Start Date</label>
                <p>{formatDate(subscription.start_date)}</p>
              </div>
              <div className="info-item">
                <label>{subscription.cancelled ? 'End Date' : 'Renewal Date'}</label>
                <p>{formatDate(subscription.end_date)}</p>
              </div>
              {subscription && !subscription.cancelled && (
                <>
                  <div className="info-item full-width">
                    <button 
                      className="action-button change-plan"
                      onClick={() => setShowUpgradeConfirm(true)}
                    >
                      Change Plan
                    </button>
                  </div>
                  <div className="info-item full-width">
                    <button 
                      className="action-button cancel-membership"
                      onClick={() => setShowCancelConfirm(true)}
                    >
                      Cancel Membership
                    </button>
                  </div>
                </>
              )}
            </div>
          ) : (
            <p className="no-data-message">No subscription information available.</p>
          )}
        </div>

        <div className="account-section">
          <div className="section-header">
            <h2>Billing Information</h2>
          </div>
          
          {/* Payment Methods Component */}
          <PaymentMethods />
        </div>

        <div className="account-section">
          <h2>Account Security</h2>
          {!showPasswordChange ? (
            <button 
              className="action-button"
              onClick={() => setShowPasswordChange(true)}
            >
              Change Password
            </button>
          ) : (
            <form className="password-form">
              {formError && <div className="error-message">{formError}</div>}
              {formSuccess && <div className="success-message">{formSuccess}</div>}
              <div className="form-group">
                <input
                  type="password"
                  placeholder="Current Password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  placeholder="New Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  placeholder="Confirm New Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
              </div>
              <div className="button-group">
                <button type="submit" 
                className="submit-button"
                onClick={handlePasswordChange}>
                  Update Password
                </button>
                <button 
                  type="button" 
                  className="cancel-button"
                  onClick={() => {
                    setShowPasswordChange(false);
                    setFormError('');
                    setFormSuccess('');
                  }}
                >
                  Cancel
                </button>
              </div>
            </form>
          )}
        </div>

        {/* Cancel Confirmation Modal */}
        <Modal
          isOpen={showCancelConfirm}
          onClose={() => setShowCancelConfirm(false)}
          title="Cancel Membership"
          actions={
            <>
              <button 
                className="modal-button secondary" 
                onClick={() => setShowCancelConfirm(false)}
              >
                Keep Membership
              </button>
              <button 
                className="modal-button primary danger" 
                onClick={handleCancelMembership}
                disabled={isLoading}
              >
                {isLoading ? 'Processing...' : 'Yes, Cancel'}
              </button>
            </>
          }
        >
          <p>Are you sure you want to cancel your membership? Your subscription will remain active until {formatDate(subscription?.end_date)}.</p>
        </Modal>

        {/* Upgrade/Downgrade Modal */}
        <Modal
          isOpen={showUpgradeConfirm}
          onClose={() => setShowUpgradeConfirm(false)}
          title="Change Plan"
        >
          <SubscriptionUpgrade
            currentPlan={subscription?.subscription_type}
            onUpgrade={handlePlanChange}
            isLoading={isLoading}
          />
        </Modal>
      </div>
    </>
  );
};

export default MyAccount;