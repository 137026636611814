import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../assets/styles/NavBar.css'; 

const NavBar = ({ onSectionClick }) => {
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  const handleClick = (e, sectionId) => {
    e.preventDefault();
    if (isHomePage) {
      onSectionClick(sectionId);
    } else {
      // If not on home page, navigate to home page first and then scroll
      window.location.href = `/#${sectionId}`;
    }
  };

  return (
    <nav className="navbar">
      <Link to="/" className="navbar-link brand-name">Finn Tech</Link>
      <Link to="/Blogs" className="navbar-link">Blog</Link>
      <a href="#social-media" className="navbar-link" onClick={(e) => handleClick(e, 'social-media')}>Social Media</a>
      <Link to="/contact" className="navbar-link">Contact</Link>
      <a href="#about" className="navbar-link" onClick={(e) => handleClick(e, 'about')}>About</a>
      <Link to="/Login" className="navbar-link login">Login</Link>
    </nav>
  );
};

export default NavBar;