import React, { useState } from 'react';
import '../assets/styles/SubscriptionUpgrade.css';

const SubscriptionUpgrade = ({ currentPlan, onUpgrade, isLoading }) => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const plans = {
    monthly: {
      price: 4.99,
      interval: 'monthly',
      priceId: 'price_1QxIYy2LqoSDKNiW7dYNZiq1'
    },
    annual: {
      price: 49.99,
      interval: 'annual',
      priceId: 'price_1QxIYH2LqoSDKNiWVli7dIjx'
    }
  };

  const handleUpgrade = () => {
    if (!selectedPlan || selectedPlan === currentPlan) return;
    onUpgrade(plans[selectedPlan].priceId);
  };

  return (
    <div className="subscription-upgrade">
      <h4>Change Plan</h4>
      <div className="plan-options">
        <div 
          className={`plan-option ${selectedPlan === 'monthly' ? 'selected' : ''} 
            ${currentPlan === 'monthly' ? 'current' : ''}`}
          onClick={() => setSelectedPlan('monthly')}
        >
          <div className="plan-name">Monthly</div>
          <div className="plan-price">${plans.monthly.price}/mo</div>
          {currentPlan === 'monthly' && <div className="current-plan">Current Plan</div>}
        </div>
        
        <div 
          className={`plan-option ${selectedPlan === 'annual' ? 'selected' : ''} 
            ${currentPlan === 'annual' ? 'current' : ''}`}
          onClick={() => setSelectedPlan('annual')}
        >
          <div className="plan-name">Annual</div>
          <div className="plan-price">${plans.annual.price}/year</div>
          <div className="plan-savings">Save 16%</div>
          {currentPlan === 'annual' && <div className="current-plan">Current Plan</div>}
        </div>
      </div>

      <button 
        className="upgrade-button"
        disabled={!selectedPlan || selectedPlan === currentPlan || isLoading}
        onClick={handleUpgrade}
      >
        {isLoading ? 'Processing...' : 'Confirm Change'}
      </button>
    </div>
  );
};

export default SubscriptionUpgrade;